<template>
    <div class="myAccount">
        <div class="myAccount-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>My Account</h5>
        </div>
        <div class="myAccount-main">
            <div class="user">
                <p>*Username</p>
                <el-input v-model="userName" clearable @focus="userFocus"></el-input>
                <button v-show="btnShow" @click="btnClick">Save</button>
            </div>
            <div class="password">
                <p>Password</p>
                <el-input v-model="password" suffix-icon="el-icon-arrow-right" @focus="passwordFocus"></el-input>
            </div>
        </div>
    </div>
</template>

<style>
.myAccount .el-input__inner {
    border: none;
    border-bottom: 1px solid #d8dadc;
    border-radius: 0;
}
</style>
<style lang="scss" scoped>
@import "./css/myAccount.scss";
.myAccount{
    padding-bottom: 140px;
}
</style>

<script>
import { getToken } from "@/utils/auth"
import { info, nickName } from "@/api/member/info"

export default {
    name: "myAccount",
    components: {},
    data: () => {
        return {
            userName: "",
            password: "",
            btnShow: false
        }
    },
    created() {
        this.getUserName();
    },
    mounted() {},
    methods: {
        getUserName() {
            let token = getToken();
            if(token){
                info({ token: token }).then( res => {
                    if(res.code == 0 && res.data){
                        this.userName = res.data.nickname;
                    }
                }).catch( err => {
                    // console.log(err)
                })
            }else{
                this.userName = "";
            }
            // console.log(this.userName)
        },
        backClick() {
            this.$router.go(-1)
        },
        userFocus() {
            this.btnShow = true
        },
        btnClick() {
            nickName({ nickname: this.userName }).then( res => {
                if(res.code == 0 && res.data){
                    this.$message.success("Successfully modified");
                    this.getUserName();
                    this.btnShow = false;
                }
            }).catch( err => {
                this.$message.error("Modification failed");
                this.btnShow = false;
                // console.log(err)
            })
        },
        passwordFocus(){
            this.$router.push({ path: '/personal/modifyPassword' });
        }
    }
}
</script>
